export function tableHeaders() {
    var propType = $("#propertyTypeSelector option:selected").val();
    if (!propType) {
      propType = 'A'
    }

    var tableHeader = $("#listingsDataTable thead tr");
    if (tableHeader.length > 0) {
      // No need to make this call if the table header is not on the page (list vs summary)
      let selectorControl = $('.selected-view');
      let viewId = null;
      if (selectorControl.length > 0)
        viewId = selectorControl.attr('data-view-id');
      else {
        let defaultViewAttrHolder = $('[data-default-view-id]')
        if (defaultViewAttrHolder.length > 0) {
          viewId = defaultViewAttrHolder.attr('data-default-view-id')
        }
      }

      if(viewId){
        $.ajax({
          url: getViewsRelativePath() + viewId + '?property_type=' + propType,
          dataType: 'json',
          success: function(d){
            tableHeader.find('th:not(.preserve)').remove(); // MLS #, Status, and Price are always preserved in the table
            $(d).each(function(i,tableTitle){
              tableHeader.append('<th>' + tableTitle + '</th>')
            });
          },
          error: function(e){
            console.log('error' + JSON.stringify(e))
          }
        })
      }
    } 
  }

function getViewsRelativePath() {
  // If you are on the listings page and call ajax with url = 'views/'  all is well.  Ajax is using a path relative to the current path.
  // But if you are on one of the sub pages viewing an ldp (listings/id), then the ajax url will take you to listings/view.  NOT A VALID PATH.
  // Or if you are in a listing report (listings/id/history), it will take you to listings/id/view.  Also NOT A VALID PATH.
  // Since the search results need the views no matter how many modal layers in you are, we still need to point to the relative views path.
  // So add a ../ to the url for each path segment beyond listings.  (And we cannot get here without the listings segment so we know it is there.)
  let url = 'views/';
  const pathSegments = window.location.pathname.split('/').filter(segment => segment !== '');
  let subDirectoryCount = 0;

  // Loop backwards through the segments
  for (let i = pathSegments.length - 1; i >= 0; i--) {
    if (pathSegments[i] !== 'listings') {
        subDirectoryCount++;
    } else {
        break;
    }
  }

  url = '../'.repeat(subDirectoryCount) + url;
  return url;
}
