import { Controller } from "stimulus";
import Url from 'domurl'
import { Basics } from '../search/basics';
import {SearchItemContainer} from '../search/search_item_container';
import {searchFormHelpers} from '../search/search_form_helpers';
import {searchFormEvents} from '../search/search_form_events';
import {Field} from '../search/field';
import { Fields } from '../search/fields'

export default class extends Controller {

  partialReloadType = {
    normal: 1,
    dateFormatChanged: 2
  }

  partialFormReloadType = this.partialReloadType.normal
  ignoreFullFormReload = true;
  filterUrl = null;
  root_url = "";

  connect() {
    /* Stuff we definitely still need */

    // TODO: see if we can unpack this
    searchFormFlex.init();

    $('.c-card-group').flexCardGroup();
    this.root_url = $('#leftTool').src;

    // A datepicker calendar event changed the date.  We need to handle the event
    // here and pass it along to the actions.
    $('#basics-content .date_field').datepicker().on('changeDate', function(e) {
      const event = new Event('change')
      this.dispatchEvent(event)
    })

    $(document).on('change', 'select:not(#locationSearch,#drawingsSelect,#templateSelect,.contact-select,.country-code-select)', (e) => {

      // If we are a regular select (instead of a select 2) we might already have the data action defined.
      // If so, no need to call it again.
      if ($(e.target).attr('data-action').includes('change->search-filters#selectChanged')) return;

      this.selectChanged(e);
    })
    $(document).on('select2:close', 'select:not(.contact-select,.country-code-select)', (e) => {
      this.selectChanged(e);
    })

    /* end stuff we definitely still need */

    Basics.previous_mls_ids = Basics.mls_id().val();

  }

  // Copied from /search/add_fields.js
  // Displays the Add Field modal
  addFields(evt) {
    let $dropdown = $(evt.target);
    if ($dropdown.css("display") == "none") {
      // Only need to hit the server if we are displaying the drop down
      showPageLoadingMsg();

      $dropdown.find('#find_field').val('').keyup(findField);

      fillAddFieldsDropdown().done(function() {
        hidePageLoadingMsg();
        $dropdown.find('#find_field').focus();
      });
    } // else no need to do anything here.  The dropdown will close without us doing anything.
  }

  // Copied from /search/add_fields.js addFieldListItemClickHandler
  // Adds a field to the form
  // This is a double event hit with the original add_fields version.  Need to remove the original.
  addField(evt) {
    evt.preventDefault();

    if ($(evt.target).parent().hasClass('included')) {
      return;
    }
    var field = new Field($(evt.target).data());
    searchFormFlex.getAndAddFieldsToForm([field]).done(function() {
      if (field.type === 'Boolean') {
        var serializedForm = searchFormHelpers.formSerializer();
        searchFormFlex.reloadData(serializedForm);
      }
    });
  }

  filterCheckboxChanged(evt) {
    evt.stopPropagation();
    searchFormFlex.filterCheckboxChanged(evt.target)
  }

  // Copied from /search/add_fields.js addAllFieldsClickHandler
  // Adds a field to the form
  // This is a double event hit with the original add_fields version.  Need to remove the original.
  addAllFields(evt) {
    evt.preventDefault();

    var fields = [];
    var groupName = $(evt.currentTarget).data("group-name");

    $('.add_a_field_list_item[data-group-name="' + groupName + '"]').each(function (i) {

      if ($(this).parent().hasClass('included')) {
        return;
      }

      var field = new Field($(this).data());
      fields.push(field);
    });

    searchFormFlex.getAndAddFieldsToForm(fields);
  }

  resetShapes(evt) {
    evt.preventDefault();
    $("#drawingsSelect").empty()
    var serializedForm = searchFormHelpers.formSerializer();
    searchFormFlex.reloadData(serializedForm);
  }

  // Copied from /search_results/search_results.js
  resetFilters(e) {
    e.preventDefault();
    // Reset the filter to its unmodified state
    if (this.filterUrl === undefined || this.filterUrl == null) {
      this.filterUrl = new Url($('.filterModalLink').attr('data-filter-url'));
    }
    this.filterUrl.query._filter = window.mapSupport.originalFilter;

    // Update leftTool src
    //let url = $('#leftTool')[0].src.split('?')[0];
    //$('#leftTool')[0].src = url + "?" + this.filterUrl.query.toString();
    $('#leftTool')[0].reload()
  }


  selectChanged(evt) {
    let target = evt.target
    // If the item's container was not checked, check it now
    SearchItemContainer.checkContainer($($(target).closest('.c-card')), ($(target).find('option:selected').length > 0));

    if ($(".select2-container--open").length > 0) {
      // Any time a select2 is open, we want to let the user
      // finish what they are doing.
      return;
    }
    if (!$(target).hasClass('boolean-group-operator-select')) {
      SearchItemContainer.updateSelectSummary(target);
    }
    if (Basics.ids.includes(target.id)) {
      if (target.id == 'search_fields_MlsStatus_value' || target.id == 'search_fields_StandardStatus_value') {
        var myStatuses = $(target).val();
        searchFormFlex.updateStatusDates(myStatuses);
      } else if (target.id == 'search_fields_MlsId_value') {
        // This is the special case where we really do want to reload the entire form.
        Fields.reload(function (data) {
          // Tell anyone who cares to know that the form data as been reloaded with
          dispatchEvent(new CustomEvent('filterForm:reloaded', {detail: { count: data.count, expressions: data.expressions }}));

          $('.c-card-group').flexCardGroup();
          // TODO:  Make sure these are bound
          //bindDateExchange();
          //bindRelativeTimeAndDirection();
        });
        return;
      }

    }
    var serializedForm = searchFormHelpers.formSerializer();
    searchFormFlex.reloadData(serializedForm, false);
  }

  select2Changed(evt) {
    searchFormFlex.select2Changed(evt.target);
  }

  searchOperatorChanged(evt) {
    searchFormFlex.searchOperatorChanged(evt);
  }

  removeField(evt) {
    evt.preventDefault();
    searchFormFlex.removeField(evt.target);
  }

  textFocus(evt) {
    $(evt.target).attr("oldVal",$(evt.target).val());
  }

  textBlur(evt) {
    // TODO:  Check to see if this is a basics field that is changing.
    var isBasicsChange = false;

    var changedItem = $(evt.target);
    if (changedItem.attr("oldVal") == changedItem.val())
      return;

    // If the item's container was not checked, check it now
    SearchItemContainer.checkContainer(changedItem.closest('.c-card'), true);

    if (changedItem.closest(".controls.split").length == 1) {
      if ($(changedItem.closest(".controls.split")[0]).hasClass('contains-date')) {
        // Only the relative date controls have the contains-date class
        SearchItemContainer.updateRelativeDateSummary($(changedItem.closest(".controls.split")[0]));
      } else {
        SearchItemContainer.updateSplitControlsSummary($(changedItem.closest(".controls.split")[0]));
      }
    } else {
      changedItem.closest('.c-card').find('.c-card-enable-summary').text(changedItem.val());
    }

    var serializedForm = searchFormHelpers.formSerializer();
    searchFormFlex.reloadData(serializedForm, isBasicsChange);
  }

  addedFieldChanged(evt) {
    evt.stopPropagation();
    searchFormFlex.fieldCheckboxChanged(evt.target);
  }

  basicsDateFieldChanged(evt) {
    if ($(".select2-container--open").length > 0) {
      // Any time a select2 is open, we want to let the user
      // finish what they are doing.
      return;
    }

    // If the item's container was not checked, check it now
    SearchItemContainer.checkContainer($(evt.currentTarget).closest('.c-card'), true);

    SearchItemContainer.updateSplitControlsSummary($($(evt.currentTarget).parent()[0]));

    var serializedForm = searchFormHelpers.formSerializer();
    searchFormFlex.reloadData(serializedForm, false)
  }

  undefined_action(evt) {
    console.log("We need to figure out how to get the right action assigned to this element.")
  }

  partialFormReload(evt) {
    if (this.partialFormReloadType == this.partialReloadType.dateFormatChanged) {
      this.partialFormReloadType = this.partialReloadType.normal
      this.updateListings();
    } else {
      this.partialFormReloadType = this.partialReloadType.normal
    }
  }

  fullFormReloaded(evt) {
    $('.c-card-group').flexCardGroup();
    // For some reason, these items still act differently than any other
    // elements.  Even the stimulus actions are not fired for these so we still
    // have to handle them separately.
    searchFormFlex.bindRelativeTimeAndDirection();
  }

  dateFormatChanged(evt) {
    // Switching between date range and relative dates
    var dateSwitch = $(evt.currentTarget);
    // If the item's container was not checked, check it now
    SearchItemContainer.checkContainer($(dateSwitch.closest('.c-card')), true);

    searchFormHelpers.toggleDateRelativity(dateSwitch);
    //searchFormHelpers.findCount();
    var isChangedToDateRange = ($('#'+ dateSwitch.attr('data-field-id').replace(/\"|\./g, '').replace(' ','_') + "_relative_container").css('display') == 'none')

    if (isChangedToDateRange) {
      SearchItemContainer.updateSplitControlsSummary($(dateSwitch.parent().find(".controls.split")[0]));
    } else {
      SearchItemContainer.updateRelativeDateSummary($('#'+ dateSwitch.attr('data-field-id').replace(/\"|\./g, '').replace(' ','_') + "_relative_container"));
    }
    this.updateListings(isChangedToDateRange);
  }

  relativeTimeOrDirectionChange(evt) {
    // IF WE CANNOT GET THESE ITEMS TO FIRE CORRECTLY, WE WILL NEED TO DELETE THIS ACTION.
    var target = $(evt.currentTarget);
    // If the item's container was not checked, check it now
    SearchItemContainer.checkContainer($(target.closest('.c-card')), true);

    var vals = target.hasClass("relative_time") ? ['days','months','years'] : ['back','ahead'] ;
    var currentVal = target.text();
    for (var x=0; x < vals.length; x++) {
      if (vals[x] == currentVal) {
        var new_val = x == vals.length-1 ? vals[0] : vals[x+1] ;
        target.text(new_val);
        $( "input[name='" + target.data("for") + "']").val(new_val);
      }
    }
    SearchItemContainer.updateRelativeDateSummary($(target.closest('.contains-date')[0]));

    this.updateListings();
  }

  // 'private' helper methods

  updateListings(isChangedToDateRange = false, isBasicsChange = false) {
    var serializedForm = searchFormHelpers.formSerializer();
    if (isChangedToDateRange) {
      this.partialFormReloadType = this.partialReloadType.dateFormatChanged
    }
    searchFormFlex.reloadData(serializedForm, isBasicsChange)
  }


  viewListings(evt) {
    leftMenubarCloseClick()
  }

  listingCountTotalChanged(data) {
    searchFormHelpers.updateResultsButton(data.detail.newValue)
  }

  filterChanged(data) {
    let newFilterString = data.detail.newValue.toString();
    
    if(window.searchResultsData){
      window.searchResultsData.filterString = newFilterString;
      window.searchResultsData.shapes = data.detail.newValue.shapes();
    }

//    $('.dynamicFilterField').val(newFilterString);

//    this.tagRecentSearch(newFilterString);

//    let oldFilterBtnUrl = filterBtn.attr('data-filter-url');
//    filterBtn.attr('data-filter-url', updateFilterInUrl(oldFilterBtnUrl, newFilterString));

//    let oldShareHref = $('.shareButton').attr('href');
//    $('.shareButton').attr('href', updateFilterInUrl(oldShareHref, newFilterString));

    if ( window.mapSupport.originalFilter.toLowerCase() === newFilterString.toLowerCase() ) {
      $('#listingsHeader').removeClass('modified-search');
      // remove the filter from the url filter
      //urlUpdater.deleteQueryParam('_filter');
      // TODO: When working on the tools menu, we need to do something here
//      $('.saveSavedSearchLink').addClass('hide');
    } else {
      $('#listingsHeader').addClass('modified-search');
      // TODO: When working on the tools menu, we need to do something here
//      $('.saveSavedSearchLink').removeClass('hide');
    }

    // Update the shape information on the form here so we dont have to reload the entire form
    var containsShapes = false;
    // Remove whatever shapes are defined in case the new filter has deleted one of the shapes.
    // We will add any that are still defined back to the select here.
    $("#drawingsSelect").find('option').remove().end();
    data.detail.newValue._data.every(function(expression) {
      if (expression.type == 'shape') {
        containsShapes = true;
        $('#drawingsSelect').append($('<option value="' + expression.condition + '" selected="selected">Shape</option>'));
      }
      return true;
    });
    if (containsShapes) {
      $("#drawingsSelectDiv").removeClass('no-shapes');
      $("#drawingsFooter").removeClass('no-shapes');
      $('div.drawn-shapes-prompt').show();
      $('div.drawn-shapes-prompt').prop('disabled',false);
      $('#drawingsSelect').prop('disabled',false);
    } else {
      $("#drawingsSelectDiv").addClass('no-shapes');
      $("#drawingsFooter").addClass('no-shapes');
    }

    //this.filterUrl = new Url(filterBtn.attr('data-filter-url'));
  }

  updateFilterInUrl(oldUrl, filter) {
    var url = new Url(oldUrl);
    url.query._filter = filter;
    return url.toString();
  }

  tagRecentSearch(filter) {

    const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}

    axios.put("tag_recent", {filter: filter}, {
      withCredentials: true,
      headers: {
        'X-CSRF-Token': token.content,
        accept: 'application/json'
      },
    }).catch((e) => {
      console.error(`Could not update recent search: ${e}`)
    });
  }
}
