import { Controller } from "stimulus";
import { addContactForm, addContactFormButton, loadCountryCodes } from "../helpers/quick_add_contact_helpers";
import { contactSelected } from "../helpers/search_helpers";

export default class extends Controller {
  static values = {
    hideFooter: Boolean
  }

  connect() {
    let countryCodeSelectOptions = "";
    for (let country of window.mapSupport.countryCodes) {
      countryCodeSelectOptions += `<option value="${country[2]}">${country[0]} (+${country[1]})</option>`
    }

    $("#newContactCountryCode").html(countryCodeSelectOptions)
  }

  addContact(evt) {
   evt.preventDefault()
   $('.modalMainForm').addClass('hidden')
   $('#addContactForm').removeClass('hidden')
   if (this.hideFooterValue) {
    $('#fastModal .c-modal__footer').addClass('hidden')
   }
  }

  cancelAddContact(evt) {
    evt.preventDefault()
    if (this.hideFooterValue) {
      $('#fastModal .c-modal__footer').removeClass('hidden')
     }
    $('#addContactForm').addClass('hidden')
    $('.modalMainForm').removeClass('hidden')
  }

  async createAContact(contact) {
    var formData = {
      utf8: "✓",
      contact: contact
    }
  
    // let url = new URL("https://m.cwoods.dev.fbsdata.com/contacts")
    let url = new URL(routeBuilderBaseUrls.mobile + "/contacts")
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    if (!response.ok) {
      return false;
    }
    
    let data = response.json();
    return data 
  }
  
  async submitAddContact(evt) {
    let options = {
      GivenName: $('#newContactFirstName').val()?.trim(),
      FamilyName: $('#newContactLastName').val()?.trim(),
      PrimaryPhoneNumber: $('#newContactPrimaryPhone').val()?.trim(),
      PrimaryEmail: $('#newContactEmail').val()?.trim(),
      MobilePhoneCountryCode: $("#newContactCountryCode").val()?.trim()
    }

    if (!this.verifyRequirements()) {
      return
    }
    
    let data = await this.createAContact(options)

    if (data.Success) {
      let contact = data.contact
      
      if (contact) {
        // Clear modal
        // Need to do this before we set the select2 value.
        // Some controllers have a moarify call that gets
        // stuck if it is not visible when the contact is
        // selected.
        $('#newContactFirstName').val("")
        $('#newContactLastName').val("")
        $('#newContactPrimaryPhone').val("")
        $("#newContactCountryCode").val("US")
        $('#newContactEmail').val("")

        if (this.hideFooterValue) {
          $('#fastModal .c-modal__footer').removeClass('hidden')
        }
        $('#addContactForm').addClass('hidden')
        $('.modalMainForm').removeClass('hidden')

        // Get the Select2 element
        var select2Element = $('.contact-select');

        // Create the new option
        var newOption = new Option(contact.DisplayName, contact.Id, true, true);

        // Add the option to Select2
        select2Element.append(newOption);
        // Set the desired value
        select2Element.val(contact.Id).trigger('change');

        // Manually trigger the select2:select event
        select2Element.trigger({
            type: 'select2:select',
            params: {
              data: {
                id: contact.Id,
                text: contact.DisplayName,
                email: contact.PrimaryEmail,
                primaryPhoneNumber: contact.PrimaryPhoneNumber,
                selected: true
              }
            }
        });
      }
      toast.success(data.Message)
    } else {
      toast.error(data.Error);
    }
  }
  
  verifyRequirements() {
    // Check requirements
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if ($('#newContactEmail').val().trim() == "") {
      toast.warning("Email cannot be empty.");
      return false;
    } else if (!emailRegex.test($('#newContactEmail').val())) {
      toast.warning("Please check email format.");
      return false;
    } 
    
    return true;
  }
}
