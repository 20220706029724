import { Controller } from "stimulus";
import { addContactForm, addContactFormButton } from "../helpers/quick_add_contact_helpers";

export default class extends Controller {

  cartBodyContent = `
    <div data-controller="quick-add-contact" data-quick-add-contact-hide-footer-value="true" >
      <div class="modalMainForm">
        <div class="form-group max-width">
          <select id="contactForCartAction" class="form-control contact-select" data-placeholder="Choose a contact"></select>
        </div>
        
        ${addContactFormButton}
        
      </div>
      ${addContactForm}
    </div>`

  cartModalButtons = `<button class="btn btn-secondary" data-dismiss="modal" type="button">Cancel</button><button disabled id="btnConfirmCartAction" data-action="click->cart-action-modal#confirm" class="btn btn-primary" data-dismiss="modal" data-contact-id="" data-listing-id="LISTING_ID_HERE" data-cart-id="CART_ID_HERE" type="button">OK</button>`

  recommendListing(event) {
    event.preventDefault();

    window.dispatchEvent(new CustomEvent("display-local-modal", {
      detail: { 
        modalCssClasses: "modal-dialog c-modal c-modal-gray cart-modal",
        modalHeader: this.getHeaderContent('Recommend Listing'),
        modalContent: this.cartBodyContent,
        modalFooterContent: this.getFooterContent(event.target.dataset.listingId, 'Recommended'),
        modalDataController: "cart-action-modal"
      }
    }));  }

  saveListing(event) {
    event.preventDefault();

    window.dispatchEvent(new CustomEvent("display-local-modal", {
      detail: { 
        modalCssClasses: "modal-dialog c-modal c-modal-gray cart-modal",
        modalHeader: this.getHeaderContent('Save Listing'),
        modalContent: this.cartBodyContent,
        modalFooterContent: this.getFooterContent(event.target.dataset.listingId, 'Favorites'),
        modalDataController: "cart-action-modal"
      }
    }));
  }

  hideListing(event) {
    event.preventDefault();

    window.dispatchEvent(new CustomEvent("display-local-modal", {
      detail: { 
        modalCssClasses: "modal-dialog c-modal c-modal-gray cart-modal",
        modalHeader: this.getHeaderContent('Hide Listing'),
        modalContent: this.cartBodyContent,
        modalFooterContent: this.getFooterContent(event.target.dataset.listingId, 'Removed'),
        modalDataController: "cart-action-modal"
      }
    }));
  }

  getFooterContent(listingId, cartId) {
    return this.cartModalButtons.replace("LISTING_ID_HERE", listingId).replace("CART_ID_HERE", cartId)
  }

  getHeaderContent(headerText) {
    return `<div class="t-title--large">${headerText}</div>`
  }

  dispatchCartAction(headerContent, bodyContent, footerContent) {
    window.dispatchEvent(new CustomEvent("display-local-modal", {
      detail: { 
        modalCssClasses: "modal-dialog c-modal c-modal-gray cart-modal",
        modalHeader: headerContent,
        modalContent: this.recommendModal,
        modalFooterContent: footerContent,
        modalDataController: "cart-action-modal"
      }
    }));

  }

}
