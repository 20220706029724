import { Controller } from "stimulus";
import {ContactsDropdown} from '../contacts_dropdown';
import axios from "axios"

export default class extends Controller {

  contactDropdown = null;
  isAgent = null;
  textSeparator = '?';
  links = {};

  // TODO:  Best way to turn off an action until we are done
  // processing???
  blockTextShare = true;
  blockShowModal = false;

  connect() {
    this.isAgent = (Flexmls.currentUser.role == 'private')

    if (this.isAgent) {
      this.blockTextShare = false;
    }
    // Mac does it different.  Where should we put this data?
    if (this.data.get("is-mac") === 'true') {
      this.textSeparator = '&'
    }

    if (typeof nativeInterface !== 'undefined' && 
        typeof nativeInterface.registerShareListings === 'function') {
      nativeInterface.registerShareListings('LISTING', this.data.get("listing-id"));
    }

    if (this.contactDropdown == null) {
      this.contactDropdown = new ContactsDropdown('.shareListingContactDropdown');

      $('.shareListingContactDropdown').on('select2:select', (event) => {
        this.contactSelected(event.params.data)
      })
    }

    // If we have a selected view, assign that view to the shared link
    if ($('.selected-view').length > 0) {
      if ($('#listing_share_view_id').length > 0) {
        $('#listing_share_view_id').val($('.selected-view').attr('data-view-id'))
      } else if ($('#collection_share_view_id').length > 0) {
        $('#collection_share_view_id').val($('.selected-view').attr('data-view-id'))
      } else if ($('#search_share_view_id').length > 0) {
        $('#search_share_view_id').val($('.selected-view').attr('data-view-id'))
      }
    }
  }

  cancel(event) {
    $('#fastModal').modal('hide');
  }

  shareByEmail(event) {
    // From share page, user has clicked the 'Email' button
    event.preventDefault();

    if (this.data.get('submit-form') === 'true' ) {
      // Non turbo action
      $('#newShareForm').submit();
    } else {
      var formData = {
      }
      var listing_share = {}
      var formElements = $('#newShareForm').find('[name]');
      for (var x = 0; x < formElements.length; x++) {
        let formElementName = $(formElements[x]).attr('name')
        let formElementVal = $(formElements[x]).val()
        if (formElementName.indexOf('listing_share[') === 0) {
          // Some sub data
          var attributeName = formElementName.substring(formElementName.indexOf('[') + 1, formElementName.indexOf(']'));
          if (!formData.listing_share) formData.listing_share = {}
          formData.listing_share[attributeName] = formElementVal;
        } else if (formElementName.indexOf('search_share[') === 0) {
          // Some sub data
          var attributeName = formElementName.substring(formElementName.indexOf('[') + 1, formElementName.indexOf(']'));
          if (!formData.search_share) formData.search_share = {}
          formData.search_share[attributeName] = formElementVal;
        } else if (formElementName.indexOf('collection_share[') === 0) {
          // Some sub data
          var attributeName = formElementName.substring(formElementName.indexOf('[') + 1, formElementName.indexOf(']'));
          if (!formData.collection_share) formData.collection_share = {}
          formData.collection_share[attributeName] = formElementVal;
        } else {
          formData[formElementName] = formElementVal
        }
      }
      
      formData["ListingIds"] = Object.keys(JSON.parse(localStorage.getItem("selectedCart")));

      console.log('FormData: ', formData)
      fetch($('#newShareForm').attr('action'), {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          $('#fastModal').modal('hide');
          toast.success(data.message);
        } else {
          toast.error(data.error);
        }
      })
    }
  }

  async shareByText(event) {
    // Frome share page, user has clicked 'Text' button
    event.preventDefault();
    let phoneNumber = event.target.getAttribute('textto');
    if (phoneNumber === null || phoneNumber.length < 10) return;
    if (this.blockTextShare) return;
    this.blockTextShare = true;
    this.source = 'text'
    let linksData = await this.createSharedLinks();
    window.location.href = "sms:" + phoneNumber + this.textSeparator + "body=" + linksData.SharedUri;
    this.blockTextShare = false;
  }

  async generateLink(event) {
    const loadingText = "Loading...";
    if ($("#ldp_share_link").val().startsWith("https://")) return;

    $("#ldp_share_link").val(loadingText);

    let listingId = event.currentTarget.getAttribute("data-listing-id");

    const url = routes.shared_links_path();
    const data = {
      shared_link: {
        ListingIds: [listingId],
        Type: "Indirect",
      },
    };

    let shareLink = await axios
      .post(url, data, {
        dataType: "json",
      })
      .then((response) => {
        return response.data.SharedUri;
      });

    $("#ldp_share_link").val(shareLink);
    $("#ldp_share_link_button").prop("disabled", false);
  }


  // Private "non-action" helpers

  contactSelected(data) {
    $("#listing_share_contact_id").val(data.id);
    $("#search_share_contact_id").val(data.id);
    $("#collection_share_contact_id").val(data.id);
    if (data.primaryPhoneNumber !== null && data.primaryPhoneNumber.length >= 10) {
      $("#text-share-btn").prop("disabled", false);
      $("#text-share-btn").attr("textto",data.primaryPhoneNumber);
    } else {
      $("#text-share-btn").prop("disabled", true);
      $("#text-share-btn").attr("textto",'');
    }
    if (data.email !== null && data.email.length >= 5) {
      $("#flexmls-share-btn").prop("disabled", false);
      $("#flexmls-share-btn").attr("emailto",data.email);
    } else {
      $("#flexmls-share-btn").prop("disabled", true);
      $("#flexmls-share-btn").attr("emailto",'');
    }
  }

  async createSharedLinks() {
    let attrs = {}
    if (!this.isAgent) {
      attrs = {
        ListingIds: [ $('#listing_share_listing_id').val() ],
        ...($('#listing_share_view_id').val() && {ViewId: $('#listing_share_view_id').val()})
      }
    } else if ( this.data.get('type') === 'Listing') {
      attrs = {
        ListingIds: [ $('#listing_share_listing_id').val()],
        ...($('#listing_share_view_id').val() && {ViewId: $('#listing_share_view_id').val()})
      }
    } else if ( this.data.get('type') === 'Collection') {
      attrs = {
        collection_share: {collection_id: $('#collection_share_collection_id').val()},
        ListingIds: Object.keys(JSON.parse(localStorage.getItem("selectedCart"))),
        ...($('#collection_share_view_id').val() && {ViewId: $('#collection_share_view_id').val()})
      }
    } else if ( this.data.get('type') === 'Search') {
      attrs = {
        SearchId: $('#search_share_saved_search_id').val(),
        ...($('#search_share_view_id').val() && {ViewId: $('#search_share_view_id').val()})
      }
    }

    // Only the modal needs Indirect link, used by copy/paste text area for agent
    // and email for consumer.
    if (this.source === 'more') {
      attrs.Type = 'Indirect';
      let response = await this.getNewLink(attrs);
      return response;
    }

    // Only the agent needs Direct link, used by Text button or Email in the modal
    if (this.isAgent) {
      if (typeof this.links.Direct === 'undefined') {
        attrs.Type = 'Direct';
        let response = await this.getNewLink(attrs);
        return response;
      } else if (this.source === 'text') {
        // If More button is clicked first and the link obtained, just need to update
        // Text button.  If Text button is clicked first and the link obtained, don't
        // need to update the modal since Indirect link creation would trigger the update there.
        this.updateTextButton();
      }
    }
  }

  async getNewLink(sharedLinkAttributes) {
    self = this;
    let directLink = '';
    let indirectLink = '';

    const response = await fetch(this.data.get('url'), {
      method: "POST",
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify({ shared_link: sharedLinkAttributes })
    });

    if (!response.ok) {
      console.log("Error retrieving links: ", response);
    }

    let linkData = response.json();
    return linkData;
  }

  sendText(self,phoneNumber) {
    // Private user && contact id are required to do anything
    if (self.isAgent && $("#listing_share_contact_id").val()) {
      // TODO: There should be a flagship check here.  If !flagship && phoneNumber !== null
      if (phoneNumber !== null) {
        if (typeof self.links.Direct !== 'undefined') {
          if (typeof nativeInterface !== 'undefined') {
            com_flexmls_native.sendSmsMessage(phoneNumber, links.Direct);
            // TODO:  Ask Matt about this.  Why are we defining the action that we just performed
            $('#text-share-btn').click( function() {
              com_flexmls_native.sendSmsMessage(phoneNumber, links.Direct);
            });
          } else {
            window.location.href = "sms:" + phonphoneNumbereNbr + self.textSeparator + "body=" + self.links.Direct;
          }
        }
      }
    } else if (self.flagship_mobile_text_available) {
      if (typeof self.links.Direct !== 'undefined') {
        $('#newShareForm').submit();
      }
    }
  }

  async copyLinkToClipboard(evt) {
    evt.preventDefault();
    this.blockTextShare = true;
    this.source = 'text'
    let linksData = await this.createSharedLinks();
    console.log("linksData: ", linksData)
    
    const textArea = document.createElement("textarea");
    textArea.value = linksData.SharedUri;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    $('#fastModal').modal('hide');
    toast.success("Link copied to clipboard");
  }

  
}