import { Controller } from "stimulus";
import { hideAllSubMenus, showSubMenu, showLastViewSubMenu } from '../helpers/sub_nav_helpers.js'
import { showLdp, replaceLastPathSegment } from '../helpers/ldp_navigation_helpers'
import Cookies from 'js-cookie';

export default class extends Controller {

  modes = ['list', 'summary', 'map', 'stats']
  
  SUMMARY_LOADING_SKELETON = `<div>
    <div style="margin-top: 16px" class="skeleton skeleton-summary-card"></div>
    <div class="skeleton skeleton-summary-card"></div>
    <div class="skeleton skeleton-summary-card"></div>
    <div class="skeleton skeleton-summary-card"></div>
  </div>`

  LIST_LOADING_SKELETON = `<div>
    <div style="margin-top: 16px" class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="skeleton skeleton-list-element"></div>
    <div class="list-loading-spinner"></div>
  </div>`

  LastViewModeKey = 'SearchResultsLastViewMode'

  initializeContent() {
    showLastViewSubMenu()
    let cookieVal = Cookies.get(this.LastViewModeKey);
    // List and summary views use the same controllers for navigation between listings.
    let navigationSource = 'list'
    switch(cookieVal) {
      case 'map':
        $('#mapViewBtn')[0].click()
        navigationSource = cookieVal
        break
      case 'stats':
        $('#statViewBtn')[0].click()
        break
      case "stats":
        this.showStatsView();
        break
      case 'list':
        $('#listViewBtn')[0].click()
        break
      case 'summary':
      default:
        $('#summaryViewBtn')[0].click()
        break
    }

    // If the LDP is in the URL, it is also
    // in the turbo-frame.  Show the overlay
    // and the overlay menu.
   let pattern = /\/listings\/(\d{26})/
   if (pattern.test(window.location.href)) {
     // TODO: prev/next initialization.  This is just getting the id and setting some default values
     let match_result = window.location.href.match(pattern)
     let ldp = match_result[1]

     let detailUrl = window.location.href.replace("/listings/", "/listing_detail/")
     showLdp({src: navigationSource, priorListings: false, moreListings: true, listingId: ldp, listingUrl: detailUrl })
   }
  }

  listingSelectCheckboxClicked(evt) {
    dispatchEvent( new CustomEvent('listing:selectEvent', {detail: $(evt.target).attr('id')} ));
  }

  showListView() {
    hideAllSubMenus();
    showSubMenu('list');
    this.changeFlexContentClass('list');
    let currentUrl = window.location.href
    let url = new URL(currentUrl);
    // If the listing ID is in the URL, remove it (and anything after it) before we fill in the list view
    let pattern = /\/listings\/\d{26}.*/
    url.pathname = url.pathname.replace(pattern, '/listings')

    // Now change the url to get just the listing data
    url.pathname = replaceLastPathSegment(url, 'list_view')
    showLeftContent(url.toString(), RightPanelAction.DELETE, this.LIST_LOADING_SKELETON);
    Cookies.set(this.LastViewModeKey,'list');
  }

  showSummaryView() {
    hideAllSubMenus();
    showSubMenu('list');
    this.changeFlexContentClass('summary');
    let currentUrl = window.location.href
    let url = new URL(currentUrl);
    // If the listing ID is in the URL, remove it (and anything after it) before we fill in the list view
    let pattern = /\/listings\/\d{26}.*/
    url.pathname = url.pathname.replace(pattern, '/listings')

    url.pathname = replaceLastPathSegment(url, 'summary_view')
    showLeftContent(url.toString(), RightPanelAction.NONE, this.SUMMARY_LOADING_SKELETON);
    url.pathname = replaceLastPathSegment(url, 'map_view')

    showRightContent(url.toString());
    Cookies.set(this.LastViewModeKey,'summary');
  }

  showStatsView() {
    hideAllSubMenus();
    this.changeFlexContentClass('stats');
    let currentUrl = window.location.href
    let url = new URL(currentUrl);
    // If the listing ID is in the URL, remove it (and anything after it) before we fill in the list view
    let pattern = /\/listings\/\d{26}.*/
    url.pathname = url.pathname.replace(pattern, '/listings')
    url.pathname = replaceLastPathSegment(url,'stats_view')
    showFullContent(url.toString());
    Cookies.set(this.LastViewModeKey,"stats");
  }

  showMapView() {
    hideAllSubMenus();
    this.changeFlexContentClass('map');
    let currentUrl = window.location.href
    let url = new URL(currentUrl);
    // If the listing ID is in the URL, remove it (and anything after it) before we fill in the list view
    let pattern = /\/listings\/\d{26}.*/
    url.pathname = url.pathname.replace(pattern, '/listings')

    url.pathname = replaceLastPathSegment(url,'map_view')
    showLeftContent(url.toString(), RightPanelAction.DELETE);
    Cookies.set(this.LastViewModeKey,'map');
  }

  // Change class of the c-flex-content wrapper based on the supplied view
  changeFlexContentClass(view) {
    if (this.modes.indexOf(view) === -1) {
      throw new Error(`Invalid view: ${view}`);
    }
    let flexContent = document.querySelector('.c-flex-content');
    this.modes.forEach(mode => {
      flexContent.classList.remove(`c-flex-content--${mode}`);
    })
    flexContent.classList.add(`c-flex-content--${view}`);
  }
}