import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    // We were loaded in a fetch and could not directly load the login window because of CORS.
    // NOW load it.
    let newLocation = new URL(Flexmls.currentUser.authUri);
    newLocation.searchParams.set('state',window.location.href)

    window.location = newLocation;
  }

}