// Multiple controllers have the ability to share a listing.  This allows them all to use common code.
export function shareListing(evt) {
  // User wants to display the share page
  evt.preventDefault();

  // This is what we want to load into the turbo-frame
  let goingToUrl = new URL(evt.target.href)
  goingToUrl.searchParams.set('flexpanel',true)


  let listingId = $(evt.target).attr('data-listing-id');

  window.dispatchEvent(new CustomEvent("display-remote-modal", {
    detail: {
      modalCssClasses: "modal-dialog c-modal c-modal-gray",
      modalHeader: `<div class="t-title--large">Share Listing</div>`,
      modalRemote: goingToUrl.href
    }
  }));
}
