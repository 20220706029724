import { Controller } from "stimulus";
import { Moarify } from "@programming/moarify";
import { ContactsDropdown } from "../contacts_dropdown";
import { translations } from "../translations.js.erb";
import { getCartCount } from "../helpers/select_listings_helpers";
import { addContactForm, addContactFormButton } from "../helpers/quick_add_contact_helpers";

export default class extends Controller {
  newlyCreatedCollections = [];

  static targets = ["button"];

  modalBodyContent = `
  <div data-controller="quick-add-contact"  data-quick-add-contact-hide-footer-value="true">
  ${addContactForm}
  <div class="modalMainForm">
    <div id='contactDropdownWrapper' class="form-group">
      <select class="form-control contactDropdown contact-select" data-placeholder="Choose a contact"></select>
      ${addContactFormButton}
    </div>
    <div class="newCollectionWrapper">
      <a href="#" class="btn btn-secondary btn-sm newCollectionBtn new-collection-button" data-action="click->add-to-collection#showNewCollectionForm">Create A New Collection</a>
      <div class="new-collection-form newCollectionForm">
        <input data-action='input->add-to-collection#handleCollectionName' type="text" class="form-control" placeholder="Name your collection..">
        <button data-action='click->add-to-collection#createNewCollection' class="btn btn-secondary btn-sm" disabled="true">Create</button>
      </div>
    </div>
    <div class="c-card">
      <div class="addToCollectionModalList"></div>
    </div>
  </div>
  </div>`;

  newModalBodyContent = `
  <div data-controller="quick-add-contact"  data-quick-add-contact-hide-footer-value="true">
  ${addContactForm}
  <div class="modalMainForm">
    <div id='contactDropdownWrapper' class="form-group">
      <select class="form-control contactDropdown contact-select" data-placeholder="Choose a contact"></select>
      ${addContactFormButton}
    </div>
    <div class="newCollectionWrapper">
      <button class="btn btn-secondary btn-sm newCollectionBtn new-collection-button" data-action="click->add-to-collection#showNewCollectionForm">Create A New Collection</button>
      <div class="new-collection-form newCollectionForm">
        <input data-action='input->add-to-collection#handleCollectionName' type="text" class="form-control" placeholder="Name your collection..">
        <button data-action='click->add-to-collection#createNewCollection' class="btn btn-secondary btn-sm" disabled="true">Create</button>
      </div>
    </div>
    <div class="c-card">
      <div class="addToCollectionModalList"></div>
    </div>
  </div>
  </div>`;

  modalButtons = `<div>
    <button class="btn btn-tertiary" data-dismiss="modal" type="button">Cancel</button>
    <button disabled id='saveToCollectionBtn' data-dismiss="modal" data-action="click->add-to-collection#addListingToCollections" class="btn btn-primary addToCollectionModalSaveBtn" type="button">Save</button>
  </div>`;

  connect() {
    this.checkButtonsEnabled();
  }

  checkButtonsEnabled() {
    this.buttonTargets.forEach((button) => {
      this.toggleButton(button, getCartCount() > 0)
    });
  }

  toggleButton(button, enabled) {
    if(enabled) {
      $(button).removeClass("disabled");
      $(button).attr("title", "")
    } else {
      $(button).addClass("disabled");
      $(button).attr("title", "Select one or more listings")
    }
  }

  addToPersonalCollection(evt) {
    evt.preventDefault()
    let modalBodyContent = this.modalBodyContent;
    if (evt.target.dataset.listingIds) {
      // current route data
      this.listingIds = evt.target.dataset.listingIds;
    } else {
      // FlexPanel route don't execute if the button is disabled
      if ($(evt.currentTarget).hasClass("disabled")) return;
      // FlexPanel route data
      modalBodyContent = this.newModalBodyContent;
      let selected = $('.selectListingsControl:checked');
      let listings = selected.toArray().map((sel) => sel.id.split('_')[1]);

      this.listingIds = listings.join(",");
    }

    window.dispatchEvent(
      new CustomEvent("display-local-modal", {
        detail: {
          modalCssClasses: "modal-dialog modal c-modal c-modal-gray",
          modalHeader: this.getHeaderContent("Add to Personal Collection"),
          modalContent: modalBodyContent,
          modalFooterContent: this.modalButtons,
        },
      })
    );

    this.personalCollectionModalOpened();
  }

  addToContactCollection(evt) {
    evt.preventDefault()
    let modalBodyContent = this.modalBodyContent;
    if (evt.target.dataset.listingIds) {
      // current route data
      this.listingIds = evt.target.dataset.listingIds;
    } else {
      // FlexPanel route don't execute if the button is disabled
      if ($(evt.currentTarget).hasClass("disabled")) return;
      // FlexPanel route data
      modalBodyContent = this.newModalBodyContent;
      let selected = $('.selectListingsControl:checked');
      let listings = selected.toArray().map((sel) => sel.id.split('_')[1]);

      this.listingIds = listings.join(",");
    }

    window.dispatchEvent(
      new CustomEvent("display-local-modal", {
        detail: {
          modalCssClasses: "modal-dialog modal c-modal c-modal-gray",
          modalHeader: this.getHeaderContent("Add to Contact Collection"),
          modalContent: modalBodyContent,
          modalFooterContent: this.modalButtons,
        },
      })
    );

    this.contactDropdown = new ContactsDropdown(".contactDropdown");
    this.contactDropdown.unbind("change", this.handleContactSelect);
    this.contactDropdown.on("change", this.handleContactSelect);

    this.contactCollectionModalOpened();
  }

  handleCollectionName() {
    var input = $(".newCollectionForm input");
    var addBtn = $(".newCollectionForm button");

    input.keyup(function (e) {
      var disabled = $(this).val().length < 1;
      addBtn.prop("disabled", disabled);
    });
  }

  handleContactSelect = () => {
    $(".newCollectionWrapper").show();
    $(".addToCollectionModalList").show();
    $(".addToCollectionModalList").html = "";
    this.loadCollections();
  };

  getHeaderContent(headerText) {
    return `<div class="t-title--large">${headerText}</div>`;
  }

  personalCollectionModalOpened() {
    $("#contactDropdownWrapper").hide();
    $(".newCollectionWrapper").show();
    $(".newCollectionForm").hide();
    // We are opening the modal.  Previously that modal started with new information.
    // Now the information comes from the controller.  So start from scratch.
    this.newlyCreatedCollections = [];
    this.loadCollections();
  }

  contactCollectionModalOpened(evt) {
    $("#contactDropdownWrapper").show();
    $(".newCollectionWrapper").hide();
    $(".newCollectionForm").hide();
    $(".addToCollectionModalList").hide();
    // We are opening the modal.  Previously that modal started with new information.
    // Now the information comes from the controller.  So start from scratch.
    this.newlyCreatedCollections = [];
  }

  showNewCollectionForm(evt) {
    evt.preventDefault();
    $(".newCollectionBtn").hide();
    $(".newCollectionForm").show();
  }

  loadCollections() {
    if (typeof this.moarify !== "undefined") {
      this.moarify.destroy();
    }

    const contactId = $(".contactDropdown").val();

    this.moarify = new Moarify({
      appendTo: document.querySelector(".addToCollectionModalList"),
      ajaxUrl: routes.collections_path(contactId),
      useSessionStorage: false,
      currentPage: 0,
      perPage: 10,
      dataType: "json",
      processResponse: this.processCollectionJson.bind(this),
      scrollElement: document.querySelector(".addToCollectionModalList"),
      infiniteScroll: true,
    });

    this.moarify.run();
  }

  processCollectionJson = (data) => {
    var collectionHtmls = [];
    var portalCartsCount = 0;

    data.collections.forEach((collection) => {
      var keeper = true;

      if (this.newlyCreatedCollections.indexOf(collection.Id) >= 0) {
        keeper = false;
      } else if (
        typeof collection.PortalCartType !== "undefined" &&
        collection.PortalCartType !== "Custom"
      ) {
        keeper = false;
        portalCartsCount++;
      }

      if (keeper) {
        collectionHtmls.push(this.buildCollectionHtml(collection));
      }

      if (typeof data.pagination !== "undefined") {
        this.moarify.setTotalItems(data.pagination.total_entries);
      }
    }, this);

    return collectionHtmls.join("");
  };

  buildCollectionHtml = (collection, checked) => {
    var checkedAttr = checked ? 'checked="checked"' : "";
    var out = '<div class="c-list-entry"><div class="c-list-entry__title">';
    out +=
      "<input " +
      checkedAttr +
      'data-action="click->add-to-collection#maybeEnableSaveButton" class="addToCollectionCheckbox" id="collection_' +
      collection.Id +
      '" type="checkbox" value="' +
      collection.Id +
      '">';
    out +=
      '<label class="add-to-collection-label" for="collection_' +
      collection.Id +
      '">' +
      collection.Name +
      "</label>";
    out += "</div></div>";

    return out;
  };

  addListingToCollection(collectionId, listingIds, contactId) {
    var data = {
      listing_ids: listingIds,
    };
    return $.ajax({
      method: "POST",
      url: routes.collection_add_listings_path(contactId, collectionId),
      data: data,
    });
  }

  addListingToCollections = (e) => {

    e.preventDefault();

    const list = document.querySelector(".addToCollectionModalList");
    var contactId = $(".contactDropdown").val() || undefined;
    var listingIds = this.listingIds;

    var checkedInputs = list.querySelectorAll("input:checked");
    var collectionIds = Array.from(checkedInputs).map((input) => input.value);

    var queue = collectionIds.length;
    var errors = 0;

    showPageLoadingMsg();

    collectionIds.forEach((collectionId) => {
      var ajaxPromise = this.addListingToCollection(
        collectionId,
        listingIds,
        contactId
      );

      ajaxPromise.fail(function (jqXHR, textStatus, errorThrown) {
        errors += 1;
      });

      ajaxPromise.always(function () {
        queue -= 1;
        if (queue === 0) {
          hidePageLoadingMsg();
          if (errors === 0) {
            hidePageLoadingMsg();
            toast.success(
              translations.listings.add_to_collection_modal.listing_added
            );
          } else {
            toast.error(
              translations.listings.add_to_collection_modal.cart_add_failure,
              false
            );
          }
        }
      });
    });
  };

  createNewCollection(evt) {
    evt.preventDefault()
    const newlyCreatedCollections = this.newlyCreatedCollections;
    const name = $(".newCollectionForm input").val();
    const contactId = $(".contactDropdown").val();
    const list = document.querySelector(".addToCollectionModalList");

    $.ajax({
      url: routes.collections_path(contactId),
      method: "POST",
      data: { collection: { Name: name } },
      success: (newCollection) => {
        newlyCreatedCollections.push(newCollection.Id);
        $(".newCollectionForm input").val("");
        $(".newCollectionForm button").prop("disabled", true);
        var html = this.buildCollectionHtml(newCollection, true);
        $(html).hide().prependTo(list).fadeIn();
        this.maybeEnableSaveButton();
        $(".newCollectionForm").hide();
        $(".newCollectionBtn").show();
      },
      error: function () {
        toast.error(
          translations.listings.add_to_collection_modal
            .error_creating_collection,
          false
        );
      },
    });
  }

  maybeEnableSaveButton() {
    const list = document.querySelector(".addToCollectionModalList");
    const checkedInputs = Array.from(list.querySelectorAll("input:checked"));
    const disabled = checkedInputs.length < 1;
    $("#saveToCollectionBtn").prop("disabled", disabled);
  }
}
