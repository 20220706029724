export const addContactForm = `
    <div id="addContactForm" class="hidden c-card">
      <div class="c-card__content">
        <div class="t-title">New Contact</div>
        <div class="form-group">
          <label class="control-label">First Name</label>
          <input id="newContactFirstName" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label class="control-label">Last Name</label>
          <input id="newContactLastName" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label class="control-label">Country Code</label>
          <select name="newContactCountryCode" id="newContactCountryCode" class="form-control country-code-select">
          </select>
        </div>
        <div class="form-group">
          <label class="control-label">Primary Phone Number</label>
          <input id="newContactPrimaryPhone" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label class="control-label flex-form-control--required">Email</label>
          <input id="newContactEmail" type="text" class="form-control" required>
        </div>
      </div>
      <div class="c-card__footer" style="border: 0;">
        <button id="add-contact-cancel-btn" class="btn btn-secondary" href="#" data-action="click->quick-add-contact#cancelAddContact">Cancel</button>
        <button id="submit-contact-btn" class="btn btn-primary" href="#" data-action="click->quick-add-contact#submitAddContact">Add Contact</button>
      </div>
    </div>
  `



export const addContactFormButton = `
    <div id="addContactBtnDiv">
      <button id="addContactBtn" class="btn btn-secondary" data-action="click->quick-add-contact#addContact carts">Add Contact</button>
    </div>`

function loadCountryCodes() {
  let countryCodeSelectOptions = "";
  
  for (let country of window.mapSupport.countryCodes) {
    countryCodeSelectOptions += `<option value="${country[2]}">${country[0]} (+${country[1]})</option>`
  }
  
  return countryCodeSelectOptions;
  
}